import React from "react"
import { Link } from "gatsby"
import { ChevronLeft, ChevronRight } from "react-feather"

const pagnation = ({pageContext}) => {
        console.log(pageContext.numPages)
        const nextPage = (pageContext.currentPage === pageContext.numPages) ? null : "/blog/" + (pageContext.currentPage + 1) + "#title"
        const prevPage = (pageContext.currentPage === 1) ? null : "/blog/" + (pageContext.currentPage - 1) + "#title"
        console.log(nextPage)
    return(
        <div className="pagnation">
            <div>
                { prevPage &&
                    <Link style={{ gridTemplateColumns: '20% auto',
                                 
                     }} to = {prevPage}>
                    <ChevronLeft color="white" size={48} />
                    <p style={{ textAlign: 'center',
                                padding: '0% 0% 0% 1%',
                 }} >Previous Page</p>
                    </Link>
                }
            </div>
            <div>
                { nextPage && 
                    <Link style={{ gridTemplateColumns: 'auto 20%', }} to = {nextPage}>
                        <p style={{ textAlign: 'center' }} >Next Page</p>
                        <ChevronRight color="white" size={48} />
                    </Link>
                 }
            </div>
        </div>
    )
}

export default pagnation