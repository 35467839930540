// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick";

import SEO from "../components/seo"
import Header from "../components/header"
import Front from "../components/front"
import Footer from "../components/footer"
import Pagnation from "../components/pagnation"

import "../components/layout.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dizBlog from "../images/dizBlog.png"
import pagnation from "../components/pagnation"

const Blog = ({data,pageContext}) =>{
 
 const featured = data.featured

 var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  }

  var maltest = () => {
    
  } 

  console.log(data)
        
return(   
        <div className="font" style={{
          background: 'black',
        }}>
        <SEO title="Blog" />  
        <Header theme = "navbar1 nav-top" />
        <div style={{
              top: '10%',
              height: '120px',
              background: 'black'
            }} ></div>
        <div className="featured">
          <div>
            <h3  >Featured</h3>
          </div>
          <Slider {...settings} >
            {data.featured.edges.map(({ node }) =>
            (
              <div style={{ padding : ' 2% 0%' }} >
                <Link to = {node.slug}>
                  <div>
                    <h2>{node.title}</h2>
                    <Link to = {node.author.name}><p>{node.author.name}</p></Link>
                  </div>
                  <div>
                    <h4 dangerouslySetInnerHTML={{__html: node.excerpt}} ></h4>
                  </div>
                  <div>
                    <Img fluid={ (node.featured_media === null)? data.black.childImageSharp.fluid : node.featured_media.localFile.childImageSharp.fluid}/>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
        
        <div className="blogformat">
          {data.allWordpressPost.edges.map(({ node }) =>(
            <div className="blog" key={node.slug}>
              <Link className={(node.tags === null) ? "title" : (node.tags[0].name == "malayalam" )? "maltitle": " " } to={node.slug}>
                <div>
                  <h2>{node.title}</h2>
                  <p className="authndcat">{node.author.name}</p>               
                  {/* <Link className="authndcat" to={node.author.name + "/#cat"}><p>{node.author.name}</p></Link> */}
                  <p className="authndcat">{node.categories[0].name}</p>
                  {/* <Link className="authndcat" to={node.categories[0].name + "/#cat"}><p>{node.categories[0].name}</p></Link> */}
                  <p>{node.date}</p>
                </div>
                <h4 dangerouslySetInnerHTML={{__html: node.excerpt}} ></h4>
                <div>
                  <Img fluid={(node.featured_media === null)? data.black.childImageSharp.fluid : node.featured_media.localFile.childImageSharp.fluid}/>
                </div>
              </Link>          
            </div>    
          ))}
        </div>
        <Pagnation pageContext={pageContext}/>
         <Footer theme = "footer1" />
      </div>     
              )
               
} 
export default Blog    

export const pageQuery = graphql`
        query ($skip: Int!, $limit: Int!) {
            allWordpressPost(limit: $limit, sort: {fields: date, order: DESC}, skip: $skip, filter: {tags: {elemMatch: {name: {ne: "Featured"}}}}) {
              edges {
                node {
                  id
                  slug
                  date(formatString: "DD/MM/YYYY")
                  title
                  excerpt
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid (maxWidth:1000) {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                  categories {
                    name
                    id
                  }
                  author {
                    name
                    id
                  }
                  tags {
                    name
                  }
                }
              }
            }
            featured : allWordpressPost(filter: {tags: {elemMatch: {name: {eq: "Featured"}}}}, sort: {fields: date, order: ASC}, limit: 5) {
              edges {
                node {
                  title
                  excerpt
                  slug
                  date(formatString: "DD/MM/YYYY")
                  author {
                    name
                  }
                  categories {
                    name
                  }
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            }
            black: file(relativePath: {eq: "dribble first-01.jpg"}) {
              childImageSharp {
                fluid (maxWidth:1000) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        `